import React, { useState, useEffect } from "react";
import { get, first, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/react-hooks";

import * as Templates from "../../templates";
import { ARTICLES_COUNT, NEWS_COUNT, ALL_PHOTO_ITEM } from "../../queries/queries.graphql";

const templatesMap = {
	Abstinence: "Abstinence",
	belltower: "Belltower",
	book: "Book",
	broadcast: "Broadcast",
	Calendar: "Calendar",
	cathedral: "Cathedral",
	chapel: "Chapel",
	church: "Church",
	city: "City",
	CityObject: "CityObject",
	Hermitage: "Hermitage",
	Holiday: "Holiday",
	Ikon: "Ikon",
	Media: "Media",
	Monastery: "Monastery",
	Museum: "Museum",
	news: "News",
	Radio: "Radio",
	Saint: "Saint",
	SaintBook: "SaintBook",
	SerafimProject: "SerafimProject",
	Shrine: "Shrine",
	Temple: "Temple",
	page: "TextPages",
	tour: "Tour",
	TV: "TV",
	Video: "Video",
	virtual_tour: "VirtualTour",
	well: "Well",
	articles: "Article",
	newsPhotos: "NewsPhotos",
};

const queriesMap = {
	"articles": {
		query: ARTICLES_COUNT,
		prefix: "media_articles_aggregate",
	},
	"news": {
		query: NEWS_COUNT,
		prefix: "news_aggregate",
	},
	"photos": {
		query: ALL_PHOTO_ITEM,
		prefix: "v_all_media_photos",
	},
	"default": {
		query: ALL_PHOTO_ITEM,
		prefix: "",
	},
};

export default function Page404({ location }) {
	const URLmatch = get(location, "pathname", "").match(/[\w-]+(?=[/\r\n?]|$)/g);
	const slug = last(URLmatch);
	const type = first(URLmatch);
	const previewData = {
		slug,
		type,
		category: !/news|articles/.test(type) ? get(URLmatch, "[1]", "") : "",
	};
	const options = {
		variables: {
			location: slug,
		},
		fetchPolicy: "network-only",
		ssr: false,
	};

	const [pageType, setPageType] = useState("");

	const previewCategory = get(previewData, "category", "") || type;

	const [
		loadContentType,
		{
			loading,
			error,
			data,
		},
	] = useLazyQuery(get(queriesMap[previewCategory] ?? queriesMap.default, "query", ""), options);

	useEffect(() => {
		if (previewData.category) {
			loadContentType();
		} else {
			setPageType(previewData.type);
		}
	}, []);

	useEffect(() => {
		if (!loading && data && !error && previewData.category) {
			const fetchedType = get(data, `${queriesMap[previewCategory]?.prefix}[0]["type"]`, "");

			setPageType(fetchedType);
		}
	}, [loading]);

	if (pageType) {
		return previewData.slug && pageType ?
			React.createElement(
				get(Templates, `[${templatesMap[pageType] || "Page404"}]`, null),
				{
					previewData,
					location,
				},
			) :
			React.createElement(
				Templates.Page404,
				{
					location,
				},
			)
		;
	}

	return React.createElement(
		Templates.Page404,
		{
			location,
		},
	);
}

Page404.propTypes = {
	location: PropTypes.object,
};

Page404.defaultProps = {
	location: {},
};

Page404.displayName = "Page404Page";

